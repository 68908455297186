import { Home } from "../views/home";
import { Login } from "../views/login";
import { Register } from "../views/register";
import { UpdateInfo } from "../views/update-info";
import { UpdatePassword } from "../views/update-password";

function ErrorPage() {
  return <div>error</div>;
}

export const routes = [
  {
    path: "/",
    element: <Home></Home>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'update-info',
        element: <UpdateInfo />
      },
      {
        path: 'bbb',
        element: <div>bbb</div>
      }
    ]
  },

  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "update_password",
    element: <UpdatePassword />,
  },
  {
    path: "update_info",
    element: <UpdateInfo />,
  }
];