import { RegisterUser } from "../views/register";
import { UserInfo } from "../views/update-info";
import { UpdatePassword } from "../views/update-password";
import axiosInstance from "./http";

export function user__login(username: string, password: string) {
  return axiosInstance.post('/user/login', {
    username, password
  });
}

export function  user__register_captcha(email: string) {
  return axiosInstance.get('/user/register-captcha', {
    params: {
      address: email
    }
  });
}
export function user__register(registerUser: RegisterUser) {
  return axiosInstance.post('/user/register', registerUser);
}

export function user__update_password__captcha(email: string) {
  return axiosInstance.get('/user/update_password/captcha', {
    params: {
      address: email
    }
  });
}
export function user__update_password(data: UpdatePassword) {
  return axiosInstance.post('/user/update_password', data);
}

export function user__info() {
  return axiosInstance.get('/user/info');
}

export function user__update(data: UserInfo) {
  return axiosInstance.post('/user/update', data);
}

export function user__update__captcha() {
  return axiosInstance.get('/user/update/captcha');
}
