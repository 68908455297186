import axios, { AxiosRequestConfig } from "axios";
import { message } from "antd";


interface PendingTask {
  config: AxiosRequestConfig
  resolve: Function
}

const axiosInstance = axios.create({
  baseURL: 'http://118.31.36.202/api',
  timeout: 100000
});

let refreshing = false;
const queue: PendingTask[] = [];

axiosInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('access_token');

  if(accessToken) {
      config.headers.authorization = 'Bearer ' + accessToken;
  }
  return config;
})

async function refreshToken() {
  const res = await axiosInstance.get('/user/refresh', {
    params: {
      refresh_token: localStorage.getItem('refresh_token')
    }
  });
  localStorage.setItem('access_token', res.data.access_token || '');
  localStorage.setItem('refresh_token', res.data.refresh_token || '');
  return res;
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if(!error.response) {
      return Promise.reject(error);
    }
    let { data, config } = error.response;

    if(refreshing) {
      return new Promise((resolve) => {
        queue.push({
          config,
          resolve
        });
      });
    }

    if (data.code === 401 && !config.url.includes('/user/refresh')) {
      
      refreshing = true;

      const res = await refreshToken();

      refreshing = false;

      if(res.status === 200 || res.status === 201) {

        queue.forEach(({config, resolve}) => {
          resolve(axiosInstance(config))
        })

        return axiosInstance(config);
      } else {
        message.error(res.data);

        setTimeout(() => {
          window.location.href = '/login';
        }, 1500);
      }
        
    } else {
      return error.response;
    }
  }
);

export default axiosInstance;